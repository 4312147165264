//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Notification from '~/components/ui/Notification';

export default {
  middleware: 'guest',
  components: {
    Notification
  },
  nuxtI18n: {
    paths: {
      'en-ca': '/login',
      'fr-ca': '/connexion'
    }
  },
  data() {
    return {
      error: null,
      password: '',
      username: ''
    };
  },
  methods: {
    async loginUser() {
      this.error = null;
      try {
        await this.$auth.loginWith('local', {
          data: {
            identifier: this.username,
            password: this.password
          }
        });
      } catch (e) {
        this.error = this.$t('error.loginError');
      }
    }
  }
};
